import React from 'react';
import styled from 'styled-components';
import { Box, Col, Flex, Text } from '../../Ui';
import { ReactComponent as MissioneIcon } from '../../../assets/images/missione.svg';
import { useState } from 'react';
import { respondTo } from '../../../theme/mixin';
const Span = styled.span`
  cursor: pointer;
  svg {
    color: ${({ theme, color }) => (color ? theme.primary : theme.colore_testo_box)};
  }
`;
const CustomFlex = styled(Flex)`
  justify-content: space-between;
  ${respondTo.md`
     justify-content: flex-start;
     `};
`;

function Missione() {
  const [listMissioni, setListMissioni] = useState([
    { id: 1, text: 'Registrati con un codice amico', active: false },
    { id: 2, text: 'Invita 3 amici a registrarsi', active: false },
    { id: 3, text: 'Metti un like', active: false },
  ]);
  const activeHandler = id => {
    setListMissioni(prev =>
      prev.map(item => (item.id === id ? { ...item, active: !item.active } : item)),
    );
  };
  const res = listMissioni.filter(el => el.active === true);
  return (
    <Box padding='0' width='80%' margin='0 0 20px 0'>
      <Flex style={{ borderBottom: '1px solid #cccccc' }}>
        <Col width={50}>
          <Text as='p' size={20} text_box>
            LOREM IPSUM
          </Text>
        </Col>
        <Col width={50} align='right'>
          <Text as='p' size={20} text_box>
            {res.length === 3 ? 100 : res.length === 2 ? 50 : res.length === 1 ? 30 : 0}% Completata
          </Text>
        </Col>
      </Flex>
      <Flex justify='space-between'>
        {listMissioni.map(item => (
          <CustomFlex align='center' width='33%' key={item.id}>
            <Col width={20}>
              <Span onClick={() => activeHandler(item.id)} color={item.active}>
                <MissioneIcon />
              </Span>
            </Col>
            <Col width={68}>
              <Text capit text_box>
                {item.text}
              </Text>
            </Col>
          </CustomFlex>
        ))}
      </Flex>
    </Box>
  );
}

export default Missione;
