import React from 'react';
import styled from 'styled-components';

const TextareaField = styled.textarea`
  outline: none;
  width: 100%;
  background-color: ${props => props.theme.input.bg};
  border: 2px solid
    ${props =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.bg};
  border-radius: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 15px;
  font-size: 14px;
  font-weight: 700;
  height: ${({ height }) => (height ? height : '100px')};
  color: ${props => props.theme.primary};
  font-family: ${props => props.theme.fontFamily};

  &::placeholder {
    color: #47545d59;
  }
`;

const Textarea = props => {
  return <TextareaField {...props} />;
};

export default Textarea;
