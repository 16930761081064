import React from 'react';
import { Flex } from '../Ui';
import Missione from './missione';

function Missioni() {
  return (
    <Flex>
      <Missione />
    </Flex>
  );
}

export default Missioni;
