import React, { useEffect, useState } from 'react';
//import StarRating from './StarRating';
import styled, { css } from 'styled-components/macro';
//import { getSingleProduct, openOrderModal } from '../../store/actions/products';
import { useHistory } from 'react-router-dom';
import { Flex, Text } from '../../Ui';
import moment from 'moment';
import StarRating from '../../product/StarRating';
import { useSelector } from 'react-redux';
const Container = styled.div`
  .order {
    margin: 20px 10px;
    &-content {
      display: flex;
      justify-content: space-between;
    }
    &-title {
      padding: 5px;
      background-color: ${({ theme }) => theme.bgboard};
    }
    &-content {
      padding: 5px;
      &:nth-child(2n + 1) {
        background-color: ${({ theme }) => theme.bgboard};
      }
    }
  }
`;
const Row = ({ label, value }) => {
  return (
    <div className='order-content'>
      <Text className='order-label' bold size={13} width='25%'>
        {label}
      </Text>
      <Text className='order-value' size={13} width='73%'>
        {value}
      </Text>
    </div>
  );
};
function OrderDetails({ inModal, setIsReviews, order, ...restProps }) {
  const {
    id,
    order_number,
    status,
    date,
    products: [
      {
        category_description,
        brand_description,
        title,
        description,
        points,
        reviews,
        rating,
        order_points,
      },
    ],
    shipping: {
      shipping_firstname,
      shipping_lastname,
      shipping_address,
      shipping_city,
      shipping_zip,
      shipping_phone,
      shipping_email,
      shipping_country,
      shipping_province,
    },
  } = order || {};

  //const dispatch = useDispatch();
  const { id: userId, score } = useSelector(state => state.user.user.userinfo || {});
  const [numRating, setNumRating] = useState(0);
  const history = useHistory();
  const pathName = window.location.pathname;

  return (
    <Container className='orders'>
      <div className='order'>
        <Text bold size={14} primary className='order-title'>
          Dettagli ordine
        </Text>
        <Row label='Punti' value={order_points} />
        <Row label='Data creazione' value={moment(date).format('DD/MM/YYYY HH:MM')} />
        <Row label='Numero ordine' value={order_number} />
      </div>
      <div className='order'>
        <Text bold size={14} primary className='order-title'>
          Prodotti
        </Text>
        <Row label='Categoria' value={category_description} />
        <Row label='Marca' value={brand_description} />
        <Row label='Descrizione/Modello' value={title} />
      </div>
      <div className='order'>
        <Text bold size={14} primary className='order-title'>
          Dati acquirente
        </Text>
        <Row label='Nome' value={shipping_firstname} />
        <Row label='Cognome' value={shipping_lastname} />
        <Row label='Email' value={shipping_email} />
      </div>
      <div className='order'>
        <Text bold size={14} primary className='order-title'>
          Dati destinatario
        </Text>
        <Row label='Nome' value={shipping_firstname} />
        <Row label='Cognome' value={shipping_lastname} />
        <Row label='Indirizzo' value={shipping_address} />
        <Row label='Città' value={shipping_city} />
        <Row label='CAP' value={shipping_zip} />
        <Row label='Telefono' value={shipping_phone} />
        <Row label='Email' value={shipping_email} />
        <Row label='Paese' value={shipping_country} />
        <Row label='Provincia' value={shipping_province} />
      </div>
      {/*       <Flex align='center' justify='space-between' style={{ padding: '0 10px' }}>
        <Flex justify='center' align='center'>
          <StarRating
            num={rating}
            numRating={numRating}
            setNumRating={setNumRating}
            //inSlider={inSlider}
            readonly={true}
            halfStar={true}
          />
          <Text as='p' style={{ marginLeft: '5px' }}>
            ({reviews?.length}){' '}
          </Text>
        </Flex>
        {!reviews?.find(elem => elem.player_id === userId) && (
          <Text
            as='div'
            size={12}
            upper
            bold
            color='blue'
            text_click
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={() => {
              setIsReviews(true);
              // !inModal && handlerModal();
            }}
          >
            INVIA RECENSIONE
          </Text>
        )}
      </Flex> */}
    </Container>
  );
}
export default OrderDetails;
