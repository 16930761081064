import moment from 'moment';

export const signinform = (email, pwd, ref, inModal) => {
  return {
    external_id: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'external_id',
        id: 'external_id',
        placeholder: 'Codice cliente',
      },
      icon: '',
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    vatcode: {
      elementType: 'input',
      // title: 'Cognome',
      elementConfig: {
        type: 'text',
        name: 'vatcode',
        id: 'vatcode',
        placeholder: '* Partita IVA',
      },
      label: '',
      icon: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: email,
        ref,
      },
      icon: 'username',
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: pwd,
      },
      icon: 'password',
      label: '',
      value: '',
      showPwd: true,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
    recordami: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'recordami',
        id: 'recordami',
      },
      label: '<p>Rimani collegato</p>',
      value: 1,

      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
  };
};

export const registrationform = (
  email,
  firstname,
  lastname,
  username,
  psw,
  pswrepeat,
  dateofbirth,
  sex,
  male,
  female,
  pswerror,
  pswconfirmerror,
  ref,
  gdpr,
  gdprAccept,
  gdprReject,
  obj,
  code,
  data = {},
) => {
  const getLabel = key => {
    return obj[key]?.required
      ? '* ' + (obj[key]?.['label_' + code] ? obj[key]?.['label_' + code] : obj[key]?.label)
      : obj[key]?.['label_' + code]
      ? obj[key]?.['label_' + code]
      : obj[key]?.label;
  };

  return {
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: obj['firstname']?.required ? '* ' + firstname : firstname,
      },
      icon: 'firstname',
      hint: obj['firstname']?.hint,
      label: ``,
      value: data && data['firstname'] ? data['firstname'] : '',
      validation: {
        required: obj['firstname']?.required,
        touched: false,
      },
      valid: data && data['firstname'] ? true : !obj['firstname']?.required,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: obj['lastname']?.required ? '* ' + lastname : lastname,
      },
      icon: 'lastname',
      hint: obj['lastname']?.hint,
      label: ``,
      value: data && data['lastname'] ? data['lastname'] : '',
      validation: {
        required: obj['lastname']?.required,
        touched: false,
      },
      valid: data && data['lastname'] ? true : !obj['lastname']?.required,
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: getLabel('email'),
      },
      icon: 'email',
      hint: obj['email']?.hint,
      label: ``,
      value: data && data['email'] ? data['email'] : '',
      validation: {
        required: obj['email']?.required,
        touched: false,
      },
      valid: data && data['email'] ? true : !obj['email']?.required,
    },
    repeatemail: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'repeatemail',
        id: 'repeatemail',
        placeholder: getLabel('repeatemail'),
      },
      icon: 'email',
      hint: obj['repeatemail']?.hint,
      label: ``,
      value: data && data['email'] ? data['email'] : '',
      validation: {
        required: obj['repeatemail']?.required,
        touched: false,
      },
      valid: data && data['email'] ? true : !obj['repeatemail']?.required,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: getLabel('password'),
      },
      // showPwd: true,
      icon: 'password',
      hint: obj['password']?.hint,
      label: ``,
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: obj['password']?.required,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: !obj['password']?.required,
    },
    repeatpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: getLabel('repeatpassword'),
      },
      icon: 'password',
      hint: obj['repeatpassword']?.hint,
      label: ``,
      showPwd: true,
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: obj['repeatpassword']?.required,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: !obj['repeatpassword']?.required,
    },
    external_id: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'external_id',
        id: 'external_id',
        placeholder: getLabel('external_id'),
      },
      icon: '',
      hint: obj['external_id']?.hint,
      label: '',
      value: data && data['external_id'] ? data['external_id'] : '',
      validation: {
        required: obj['external_id']?.required,
        touched: false,
      },
      valid: data && data['external_id'] ? true : !obj['external_id']?.required,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: getLabel('phone'),
      },
      icon: 'phone',
      hint: obj['phone']?.hint,
      label: '',
      value: data && data['phone'] ? data['phone'] : '',
      validation: {
        required: obj['phone']?.required,
        touched: false,
      },
      valid: data && data['phone'] ? true : !obj['phone']?.required,
    },
    landline_phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'landline_phone',
        id: 'landline_phone',
        placeholder: getLabel('landline_phone'),
      },
      icon: 'phone',
      hint: obj['landline_phone']?.hint,
      label: '',
      value: data && data['landline_phone'] ? data['landline_phone'] : '',
      validation: {
        required: obj['landline_phone']?.required,
        touched: false,
      },
      valid: data && data['landline_phone'] ? true : !obj['landline_phone']?.required,
    },
    address1: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'address1',
        id: 'address1',
        placeholder: getLabel('address1'),
      },
      icon: 'adress',
      hint: obj['address1']?.hint,
      label: '',
      value: data && data['address1'] ? data['address1'] : '',
      validation: {
        required: obj['address1']?.required,
        touched: false,
      },
      valid: data && data['address1'] ? true : !obj['address1']?.required,
    },
    address2: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'address2',
        id: 'address2',
        placeholder: getLabel('address2'),
      },
      icon: 'adress',
      hint: obj['address2']?.hint,
      label: '',
      value: data && data['address2'] ? data['address2'] : '',
      validation: {
        required: obj['address2']?.required,
        touched: false,
      },
      valid: data && data['address2'] ? true : !obj['address2']?.required,
    },
    country: {
      elementType: 'select',
      isCountry: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona paese',
            disabled: true,
          },
        ],
        placeholder: getLabel('country'),
        name: 'country',
      },
      label: '',
      hint: obj['country']?.hint,
      value: data && data['country'] ? data['country'] : '',
      validation: {
        required: obj['country']?.required,
        touched: false,
      },
      errorMessage: `is required`,
      valid: data && data['country'] ? true : !obj['country']?.required,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona provincia',
            disabled: true,
          },
        ],
        placeholder: getLabel('district'),
        name: 'district',
      },
      label: '',
      value: data && data['district'] ? data['district'] : '',
      hint: obj['district']?.hint,
      validation: {
        required: obj['district']?.required,
        touched: false,
      },
      valid: data && data['district'] ? true : !obj['district']?.required,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona città',
            disabled: true,
          },
        ],
        placeholder: getLabel('city'),
        name: 'city',
      },
      label: '',
      hint: obj['city']?.hint,
      value: data && data['city'] ? data['city'] : '',
      validation: {
        required: obj['city']?.required,
        touched: false,
      },
      valid: data && data['city'] ? true : !obj['city']?.required,
    },
    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'zipcode',
        id: 'zipcode',
        placeholder: getLabel('zipcode'),
      },
      icon: 'zipcode',
      hint: obj['zipcode']?.hint,
      label: ``,
      value: data && data['zipcode'] ? data['zipcode'] : '',
      validation: {
        required: obj['zipcode']?.required,
        touched: false,
      },
      valid: data && data['zipcode'] ? true : !obj['zipcode']?.required,
    },
    conchiglia_card: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'conchiglia_card',
        id: 'conchiglia_card',
        placeholder: getLabel('conchiglia_card'),
      },
      label: '',
      value: data && data['conchiglia_card'] ? data['conchiglia_card'] : '',
      hint: obj['conchiglia_card']?.hint,
      validation: {
        required: obj['conchiglia_card']?.required,
        touched: false,
      },
      valid: data && data['conchiglia_card'] ? true : !obj['conchiglia_card']?.required,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: getLabel('dob'),
        name: 'dob',
      },
      icon: '',
      hint: obj['dob']?.hint,
      label: '',
      value: data && data['dob'] ? data['dob'] : '',
      valuetosend: '',
      validation: {
        required: obj['dob']?.required,
        touched: false,
        isAdult: true,
        maxDate: 'today',
      },
      errorMessage: `La registrazione è consentita solo agli utenti maggiorenni`,
      valid: data && data['dob'] ? true : !obj['dob']?.required,
    },
    gender: {
      elementType: 'inputradio',
      elementConfig: {
        type: 'radio',
        placeholder: '',
        name: 'gender',
        id: 'gender',
      },
      label: getLabel('gender'),
      value: data && data['gender'] ? data['gender'] : '',
      hint: obj['gender']?.hint,
      validation: {
        required: obj['gender']?.required,
        touched: false,
      },
      valid: data && data['gender'] ? true : !obj['gender']?.required,
      inputs: [
        {
          elementConfig: {
            type: 'radio',
            placeholder: '',
            name: 'gender',
            value: 'M',
            id: 'male',
          },
          label: male,
        },
        {
          elementConfig: {
            type: 'radio',
            placeholder: '',
            name: 'gender',
            value: 'F',
            id: 'female',
          },
          label: female,
        },
      ],
    },
    company: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'company',
        id: 'company',
        placeholder: getLabel('company'),
      },
      icon: '',
      label: '',
      hint: obj['company']?.hint,
      value: data && data['company'] ? data['company'] : '',
      validation: {
        required: obj['company']?.required,
        touched: false,
      },
      valid: data && data['company'] ? true : !obj['company']?.required,
    },

    vatcode: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'vatcode',
        id: 'vatcode',
        placeholder: getLabel('vatcode'),
      },
      icon: '',
      hint: obj['vatcode']?.hint,
      label: '',
      value: data && data['vatcode'] ? data['vatcode'] : '',
      validation: {
        required: obj['vatcode']?.required,
        touched: false,
      },
      valid: data && data['vatcode'] ? true : !obj['vatcode']?.required,
    },
    fiscalcode: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'fiscalcode',
        id: 'fiscalcode',
        placeholder: getLabel('fiscalcode'),
      },
      icon: '',
      hint: obj['fiscalcode']?.hint,
      label: '',
      value: data && data['fiscalcode'] ? data['fiscalcode'] : '',
      validation: {
        required: obj['fiscalcode']?.required,
        touched: false,
      },
      valid: data && data['fiscalcode'] ? true : !obj['fiscalcode']?.required,
    },
    cluster_name: {
      elementType: 'select',
      elementConfig: {
        options: obj['cluster_name']
          ? [
              { value: '', displayValue: 'Seleziona', disabled: true },
              ...obj['cluster_name']?.options?.map(opt => {
                return {
                  value: opt.value,
                  displayValue: opt.name,
                };
              }),
            ]
          : [],
        placeholder: '',
        name: 'cluser_name',
      },
      label: obj['cluster_name']?.required
        ? '* ' + obj['cluster_name']?.label
        : obj['cluster_name']?.label,
      value: data && data['cluster_name'] ? data['cluster_name'] : '',
      validation: {
        required: obj['cluster_name']?.required,
        touched: false,
      },
      valid: data && data['cluster_name'] ? true : !obj['cluster_name']?.required,
    },

    is_company_owner: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'is_company_owner',
        id: 'is_company_owner',
      },
      label: obj['is_company_owner']?.required
        ? '<span style="display: flex">* ' + obj['is_company_owner']?.label + '</span>'
        : obj['is_company_owner']?.label,
      value: 0,
      hint: obj['is_company_owner']?.hint,
      validation: {
        required: obj['is_company_owner']?.required,
        touched: false,
      },
      valid: !obj['is_company_owner']?.required,
    },
    gdpr_privacy: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_privacy',
        id: 'gdpr_privacy',
      },
      label: obj['gdpr_privacy']?.required
        ? '<span style="display: flex">* ' + obj['gdpr_privacy']?.label + '</span>'
        : obj['gdpr_privacy']?.label,
      value: 0,
      hint: obj['gdpr_privacy']?.hint,
      validation: {
        required: obj['gdpr_privacy']?.required,
        touched: false,
      },
      valid: !obj['gdpr_privacy']?.required,
    },
    gdpr_marketing: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_marketing',
        id: 'gdpr_marketing',
      },
      label: obj['gdpr_marketing']?.required
        ? '<span style="display: flex">* ' + obj['gdpr_marketing']?.label + '</span>'
        : obj['gdpr_marketing']?.label,
      value: 0,
      hint: obj['gdpr_marketing']?.hint,
      validation: {
        required: obj['gdpr_marketing']?.required,
        touched: false,
      },
      valid: !obj['gdpr_marketing']?.required,
    },
    gdpr_profiling: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_profiling',
        id: 'gdpr_profiling',
      },
      label: obj['gdpr_profiling']?.required
        ? '<span style="display: flex">* ' + obj['gdpr_profiling']?.label + '</span>'
        : obj['gdpr_profiling']?.label,
      value: null,
      hint: obj['gdpr_profiling']?.hint,
      validation: {
        required: obj['gdpr_profiling']?.required,
        touched: false,
      },
      valid: !obj['gdpr_profiling']?.required,
    },
    accetta_regolamento: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accetta_regolamento',
        id: 'accetta_regolamento',
      },
      label: obj['accetta_regolamento']?.required
        ? '<span style="display: flex">* ' + obj['accetta_regolamento']?.label + '</span>'
        : obj['accetta_regolamento']?.label,
      value: null,
      hint: obj['accetta_regolamento']?.hint,
      validation: {
        required: obj['accetta_regolamento']?.required,
        touched: false,
      },
      valid: !obj['accetta_regolamento']?.required,
    },
    accetta_regolamento_concorso: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accetta_regolamento_concorso',
        id: 'accetta_regolamento_concorso',
      },
      label: obj['accetta_regolamento_concorso']?.required
        ? '<span style="display: flex">* ' + obj['accetta_regolamento_concorso']?.label + '</span>'
        : obj['accetta_regolamento_concorso']?.label,
      value: null,
      hint: obj['accetta_regolamento_concorso']?.hint,
      validation: {
        required: obj['accetta_regolamento_concorso']?.required,
        touched: false,
      },
      valid: !obj['accetta_regolamento_concorso']?.required,
    },
    newsletter_subscription: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'newsletter_subscription',
        id: 'newsletter_subscription',
      },
      label: obj['newsletter_subscription']?.required
        ? '<span style="display: flex">* ' + obj['newsletter_subscription']?.label + '</span>'
        : obj['newsletter_subscription']?.label,
      value: 0,
      hint: obj['newsletter_subscription']?.hint,
      validation: {
        required: obj['newsletter_subscription']?.required,
        touched: false,
      },
      valid: !obj['newsletter_subscription']?.required,
    },
  };
};
export const UserActionForm = (user, obj) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: obj['email']?.type,
        name: obj['email']?.name,
        id: obj['email']?.name,
        placeholder: 'Email',
        //disabled: true,
      },
      label: '',
      value: user?.email,
      validation: {
        required: obj['email']?.required,
        touched: false,
      },
      valid: user.email ? true : !obj['email']?.required,
    },
    firstname: {
      elementType: 'input',
      // title: 'Nome',
      elementConfig: {
        type: obj['firstname']?.type,
        name: obj['firstname']?.name,
        id: obj['firstname']?.name,
        placeholder: obj['firstname']?.value,
        // disabled: true,
      },
      label: '',
      value: user?.firstname,
      validation: {
        required: obj['firstname']?.required,
        touched: false,
      },
      valid: user.firstname ? true : !obj['firstname']?.required,
    },
    lastname: {
      elementType: 'input',
      // title: 'Cognome',
      elementConfig: {
        type: obj['lastname']?.type,
        name: obj['lastname']?.name,
        id: obj['lastname']?.name,
        placeholder: obj['lastname']?.value,
        // disabled: true,
      },
      label: '',
      value: user?.lastname,
      validation: {
        required: obj['lastname']?.required,
        touched: false,
      },
      valid: user.lastname ? true : !obj['lastname']?.required,
    },
    phone: {
      elementType: 'input',
      // title: 'Phone',
      elementConfig: {
        type: obj['phone']?.type,
        name: obj['phone']?.name,
        id: obj['phone']?.name,
        placeholder: obj['phone']?.label,
      },
      label: '',
      value: user?.phone,
      validation: {
        required: obj['phone']?.required,
        touched: false,
      },
      valid: user.phone ? true : !obj['phone']?.required,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: 'Data di nascita',
        name: 'dob',
      },
      icon: '',
      label: 'Data di nascita',
      value: user.dob ? moment(user.dob).format('DD/MM/YYYY') : '',
      valuetosend: user.dob ? moment(user.dob).format('L') : '',
      validation: {
        required: obj['dob']?.required,
        touched: false,
        maxDate: 'today',
      },
      valid: user?.dob ? true : !obj['dob']?.required,
    },
    is_company_owner: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'is_company_owner',
        id: 'is_company_owner',
      },
      label: obj['is_company_owner']?.required
        ? '<span style="display: flex">* ' + obj['is_company_owner']?.label + '</span>'
        : obj['is_company_owner']?.label,
      value: 0,

      validation: {
        required: obj['is_company_owner']?.required,
        touched: false,
      },
      valid: !obj['is_company_owner']?.required,
    },
    gdpr_privacy: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_privacy',
        id: 'gdpr_privacy',
      },
      label: obj['gdpr_privacy']?.required
        ? '<span style="display: flex">* ' + obj['gdpr_privacy']?.label + '</span>'
        : obj['gdpr_privacy']?.label,
      value: 0,

      validation: {
        required: obj['gdpr_privacy']?.required,
        touched: false,
      },
      valid: !obj['gdpr_privacy']?.required,
    },
    gdpr_marketing: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_marketing',
        id: 'gdpr_marketing',
      },
      label: obj['gdpr_marketing']?.required
        ? '<span style="display: flex">* ' + obj['gdpr_marketing']?.label + '</span>'
        : obj['gdpr_marketing']?.label,
      value: 0,

      validation: {
        required: obj['gdpr_marketing']?.required,
        touched: false,
      },
      valid: !obj['gdpr_marketing']?.required,
    },
    gdpr_profiling: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_profiling',
        id: 'gdpr_profiling',
      },
      label: obj['gdpr_profiling']?.required
        ? '<span style="display: flex">* ' + obj['gdpr_profiling']?.label + '</span>'
        : obj['gdpr_profiling']?.label,
      value: null,

      validation: {
        required: obj['gdpr_profiling']?.required,
        touched: false,
      },
      valid: !obj['gdpr_profiling']?.required,
    },
    accetta_regolamento: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accetta_regolamento',
        id: 'accetta_regolamento',
      },
      label: obj['accetta_regolamento']?.required
        ? '<span style="display: flex">* ' + obj['accetta_regolamento']?.label + '</span>'
        : obj['accetta_regolamento']?.label,
      value: null,

      validation: {
        required: obj['accetta_regolamento']?.required,
        touched: false,
      },
      valid: !obj['accetta_regolamento']?.required,
    },
    accetta_regolamento_concorso: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accetta_regolamento_concorso',
        id: 'accetta_regolamento_concorso',
      },
      label: obj['accetta_regolamento_concorso']?.required
        ? '<span style="display: flex">* ' + obj['accetta_regolamento_concorso']?.label + '</span>'
        : obj['accetta_regolamento_concorso']?.label,
      value: null,

      validation: {
        required: obj['accetta_regolamento_concorso']?.required,
        touched: false,
      },
      valid: !obj['accetta_regolamento_concorso']?.required,
    },
    newsletter_subscription: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'newsletter_subscription',
        id: 'newsletter_subscription',
      },
      label: obj['newsletter_subscription']?.required
        ? '<span style="display: flex">* ' + obj['newsletter_subscription']?.label + '</span>'
        : obj['newsletter_subscription']?.label,
      value: 0,

      validation: {
        required: obj['newsletter_subscription']?.required,
        touched: false,
      },
      valid: !obj['newsletter_subscription']?.required,
    },
  };
};

export const recoveryPasswordForm = (emailLabel, ref) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: emailLabel,
        ref,
      },
      icon: 'email',
      label: ``,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};
export const AcceptRulesForm = ({ obj }) => {
  return {
    gdpr_privacy: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_privacy',
        id: 'gdpr_privacy',
      },
      label: obj['gdpr_privacy']?.required
        ? '<span style="display: flex">* ' + obj['gdpr_privacy']?.label + '</span>'
        : obj['gdpr_privacy']?.label,
      value: 0,
      hint: obj['gdpr_privacy']?.hint,
      validation: {
        required: obj['gdpr_privacy']?.required,
        touched: false,
      },
      valid: !obj['gdpr_privacy']?.required,
    },
    accetta_regolamento: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accetta_regolamento',
        id: 'accetta_regolamento',
      },
      label: obj['accetta_regolamento']?.required
        ? '<span style="display: flex">* ' + obj['accetta_regolamento']?.label + '</span>'
        : obj['accetta_regolamento']?.label,
      value: null,
      hint: obj['accetta_regolamento']?.hint,
      validation: {
        required: obj['accetta_regolamento']?.required,
        touched: false,
      },
      valid: !obj['accetta_regolamento']?.required,
    },
  };
};
export const changePasswordForm = (
  newPswLabel,
  confirmNewPsw,
  pswerror,
  pswconfirmerror,
  pswwrong,
  ref,
) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
        ref,
      },
      label: 'Password',
      value: '',
      validation: {
        isPassword: true,
        required: true,
        touched: false,
      },
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: `${newPswLabel}`,
        ref,
      },
      label: 'Nuova password',
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: `${confirmNewPsw}`,
      },
      label: 'Conferma nuova password',
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const resetPasswordForm = (newPswLabel, confirmNewPsw, pswerror, pswconfirmerror, ref) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: newPswLabel,
        ref,
      },
      icon: 'password',
      label: ``,
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: confirmNewPsw,
      },
      icon: 'password',
      label: ``,
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const uploadReceiptForm = (dateLabel, numberLabel, amountLabel, timeLabel, ref) => {
  return {
    file: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica',
        name: 'file',
        id: 'file',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
      ref,
    },
    /* fileextra: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica il retro dello scontrino',
        name: 'fileextra',
        id: 'fileextra',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    }, */
    store: {
      elementType: 'select',
      isPdv: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Punto vendita',
            disabled: false,
          },
        ],
        placeholder: 'Punto vendita',
        name: 'store',
      },
      label: '',
      value: '',
      validation: {
        required: true,
      },
      valid: false,
    },
    date: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: `Data`,
        name: 'date',
      },
      label: '',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
        maxDate: 'today', //mm-dd-yyyy or today
      },
      valid: false,
    },
    time: {
      elementType: 'datepicker',
      elementConfig: {
        // type: 'time',
        name: 'time',
        id: 'time',
        placeholder: `Orario`,
        showTimeSelect: true,
        showTimeSelectOnly: true,
        timeIntervals: 1,
        dateFormat: 'HH:mm',
        timeFormat: 'HH:mm',
      },
      label: '',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
        maxDate: 'today',
      },
      valid: false,
    },
    number: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'number',
        id: 'number',
        placeholder: `${numberLabel}`,
      },
      label: '',
      value: '',
      min: '0',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    amount: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'amount',
        id: 'amount',
        placeholder: `${amountLabel}`,
      },
      label: '',
      value: '',

      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    code: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'code',
        id: 'code',
        placeholder: `Codice`,
      },
      label: '',
      value: '',
      min: '0',
      transparent: true,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};
export const uploadVideoForm = (dateLabel, numberLabel, amountLabel, timeLabel, ref) => {
  return {
    file: {
      elementType: 'videoFile',
      elementConfig: {
        type: 'file',
        accept: 'video/*',
        placeholder: 'Carica',
        name: 'file',
        id: 'file',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
      ref,
    },
  };
};
export const concorsoForm = ({ maxDate, pdf, socials }) => {
  return {
    social_facebook: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'social_facebook',
        id: 'social_facebook',
        placeholder: '',
      },
      label: '',
      value: socials?.facebook,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    social_instagram: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'social_instagram',
        id: 'social_instagram',
        placeholder: '',
      },
      label: '',
      value: socials?.instagram,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    file: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica',
        name: 'file',
        id: 'file',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    },
  };
};
export const contactForm = contactTopics => {
  return {
    topic: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: '', displayValue: `Seleziona argomento`, disabled: true },
          ...contactTopics.map(item => {
            return { value: item.id, displayValue: item.name };
          }),
        ],
        placeholder: `Seleziona argomento`,
        name: 'topic',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    message: {
      elementType: 'textarea',
      elementConfig: {
        name: 'message',
        id: 'message',
        placeholder: 'Il tuo messaggio',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const profileform = (user, obj, gdpr, gdprAccept, gdprReject) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: obj['email']?.type,
        name: obj['email']?.name,
        id: obj['email']?.name,
        placeholder: 'Email',
      },
      label: obj['email']?.value,
      value: user.email,
      validation: {
        required: obj['email']?.required,
        touched: false,
      },
      valid: user.email ? true : !obj['email']?.required,
    },
    repeatemail: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'repeatemail',
        id: 'repeatemail',
        placeholder: obj['repeatemail']?.required
          ? '* ' + obj['repeatemail'].label
          : obj['repeatemail'].label,
      },
      hint: obj['repeatemail']?.hint,
      label: obj['repeatemail']?.label,
      value: user.email,
      validation: {
        required: obj['repeatemail']?.required,
        touched: false,
      },
      valid: user.email ? true : !obj['email']?.required,
    },
    firstname: {
      elementType: 'input',
      // title: 'Nome',
      elementConfig: {
        type: obj['firstname']?.type,
        name: obj['firstname']?.name,
        id: obj['firstname']?.name,
        placeholder: obj['firstname']?.value,
      },
      label: obj['firstname']?.value,
      value: user.firstname,
      validation: {
        required: obj['firstname']?.required,
        touched: false,
      },
      valid: user.firstname ? true : !obj['firstname']?.required,
    },
    lastname: {
      elementType: 'input',
      // title: 'Cognome',
      elementConfig: {
        type: obj['lastname']?.type,
        name: obj['lastname']?.name,
        id: obj['lastname']?.name,
        placeholder: obj['lastname']?.value,
      },
      label: obj['lastname']?.value,
      value: user.lastname,
      validation: {
        required: obj['lastname']?.required,
        touched: false,
      },
      valid: user.lastname ? true : !obj['lastname']?.required,
    },

    phone: {
      elementType: 'input',
      // title: 'Phone',
      elementConfig: {
        type: obj['phone']?.type,
        name: obj['phone']?.name,
        id: obj['phone']?.name,
        placeholder: obj['phone']?.value,
      },
      label: obj['phone']?.value,
      value: user.phone,
      validation: {
        required: obj['phone']?.required,
        touched: false,
      },
      valid: user.phone ? true : !obj['phone']?.required,
    },
    address1: {
      elementType: 'input',
      elementConfig: {
        type: obj['address1']?.type,
        name: obj['address1']?.name,
        id: obj['address1']?.name,
        placeholder: obj['address1']?.value,
      },

      label: obj['address1']?.value,
      value: user.address1,
      validation: {
        required: obj['address1']?.required,
        touched: false,
      },
      valid: user.adress1 ? true : !obj['address1']?.required,
    },
    address2: {
      elementType: 'input',
      elementConfig: {
        type: obj['address2']?.type,
        name: obj['address2']?.name,
        id: obj['address2']?.name,
        placeholder: obj['address2']?.label,
      },

      label: obj['address2']?.label,
      value: user.address2,
      validation: {
        required: obj['address2']?.required,
        touched: !obj['address2']?.required,
      },
      valid: user.adress2 ? true : !obj['address2']?.required,
    },

    country: {
      elementType: 'select',
      isCountry: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona paese',
            disabled: true,
          },
        ],
        placeholder: obj['country']?.value,
        name: obj['country']?.name,
      },
      label: obj['country']?.value,
      value: user.country ? user.country : '',
      validation: {
        required: obj['country']?.required,
        touched: false,
      },
      errorMessage: `is required`,
      valid: user.country ? true : false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona provincia',
            disabled: true,
          },
        ],
        placeholder: obj['district']?.value,
        name: obj['district']?.name,
      },
      label: obj['district']?.value,
      value: user.district ? user.district : '',
      validation: {
        required: obj['district']?.required,
        touched: false,
      },
      valid: user.district ? true : !obj['district']?.required,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona città',
            disabled: true,
          },
        ],
        placeholder: obj['city']?.value,
        name: obj['city']?.name,
      },
      label: obj['city']?.value,
      value: user.city ? user.city : '',
      validation: {
        required: obj['city']?.required,
        touched: false,
      },
      valid: user.city ? true : !obj['city']?.required,
    },

    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: obj['zipcode']?.type,
        name: obj['zipcode']?.name,
        id: obj['zipcode']?.name,
        placeholder: obj['zipcode']?.value,
      },

      label: obj['zipcode']?.value,
      value: user.zipcode,
      validation: {
        required: obj['zipcode']?.required,
        touched: false,
      },
      valid: user.zipcode ? true : !obj['zipcode']?.required,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: 'Data di nascita',
        name: 'dob',
      },
      icon: '',
      label: 'Data di nascita',
      value: user.dob ? moment(user.dob).format('DD/MM/YYYY') : '',
      valuetosend: user.dob ? moment(user.dob).format('L') : '',
      validation: {
        required: obj['dob']?.required,
        touched: false,
        maxDate: 'today',
      },
      valid: user?.dob ? true : !obj['dob']?.required,
    },

    gender: {
      elementType: 'inputradio',
      elementConfig: {
        type: 'radio',
        placeholder: '',
        name: 'gender',
        id: 'gender',
      },
      label: 'Sesso',
      value: user?.gender,
      validation: {
        required: obj['gender']?.required,
        touched: false,
      },
      valid: user?.gender ? true : !obj['gender']?.required,
      inputs: [
        {
          elementConfig: {
            type: 'radio',
            placeholder: '',
            name: 'gender',
            value: 'M',
            id: 'male',
          },
          label: 'Maschio',
        },
        {
          elementConfig: {
            type: 'radio',
            placeholder: '',
            name: 'gender',
            value: 'F',
            id: 'female',
          },
          label: 'Femmina',
        },
      ],
    },
    company: {
      elementType: 'input',
      // title: 'Cognome',
      elementConfig: {
        type: obj['company']?.type,
        name: obj['company']?.name,
        id: obj['company']?.name,
        placeholder: obj['company']?.value,
      },
      label: obj['company']?.value,
      value: user.company,
      validation: {
        required: obj['company']?.required,
        touched: false,
      },
      valid: user.company ? true : !obj['company']?.required,
    },
    vatcode: {
      elementType: 'input',
      // title: 'Cognome',
      elementConfig: {
        type: obj['vatcode']?.type,
        name: obj['vatcode']?.name,
        id: obj['vatcode']?.name,
        placeholder: obj['vatcode']?.value,
      },
      label: obj['vatcode']?.value,
      value: user.vatcode,
      validation: {
        required: obj['vatcode']?.required,
        touched: false,
      },
      valid: user.vatcode ? true : !obj['vatcode']?.required,
    },
    fiscalcode: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'fiscalcode',
        id: 'fiscalcode',
        placeholder: 'Codice fiscale',
      },
      icon: '',
      label: 'Codice fiscale',
      value: user?.fiscalcode,
      validation: {
        required: obj['fiscalcode']?.required,
        touched: false,
      },
      valid: user?.fiscalcode ? true : !obj['fiscalcode']?.required,
    },
    cluster_name: {
      elementType: 'select',
      elementConfig: {
        options: obj['cluster_name']
          ? [
              { value: '', displayValue: 'Seleziona', disabled: true },
              ...obj['cluster_name']?.options.map(opt => {
                return {
                  value: opt.value,
                  displayValue: opt.name,
                };
              }),
            ]
          : [],
        placeholder: '',
        name: 'cluser_name',
      },
      label: obj['cluster_name']?.required
        ? '* ' + obj['cluster_name']?.label
        : obj['cluster_name']?.label,
      value: user?.cluster_name,
      validation: {
        required: obj['cluster_name']?.required,
        touched: false,
      },
      valid: user?.cluster_name ? true : !obj['cluster_name']?.required,
    },
    is_company_owner: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'is_company_owner',
        id: 'is_company_owner',
      },
      label: obj['is_company_owner']?.label,
      value: user?.is_company_owner || 0,
      hint: obj['is_company_owner']?.hint,
      validation: {
        required: obj['is_company_owner']?.required,
        touched: false,
      },
      valid: user.is_company_owner ? true : !obj['is_company_owner']?.required,
    },
    gdpr_privacy: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_privacy',
        id: 'gdpr_privacy',
      },
      label: obj['gdpr_privacy']?.label,
      value: user.gdpr_privacy || 0,
      validation: {
        required: obj['gdpr_privacy']?.required,
        touched: false,
      },
      valid: user.gdpr_privacy ? true : !obj['gdpr_privacy']?.required,
    },
    gdpr_marketing: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_marketing',
        id: 'gdpr_marketing',
      },
      label: `${obj['gdpr_marketing']?.label}`,
      value: user?.gdpr_marketing || 0,
      validation: {
        required: obj['gdpr_marketing']?.required,
        touched: false,
      },
      valid: user.gdpr_marketing ? true : !obj['gdpr_marketing']?.required,
    },
    gdpr_profiling: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_profiling',
        id: 'gdpr_profiling',
      },
      label: obj['gdpr_profiling']?.label,
      value: user?.gdpr_profiling || 0,
      validation: {
        required: obj['gdpr_profiling']?.required,
        touched: false,
      },
      valid: user?.gdpr_profiling ? true : !obj['gdpr_profiling']?.required,
    },
    accetta_regolamento: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accetta_regolamento',
        id: 'accetta_regolamento',
      },
      label: obj['accetta_regolamento']?.label,
      value: user?.accetta_regolamento || 0,
      hint: obj['accetta_regolamento']?.hint,
      validation: {
        required: obj['accetta_regolamento']?.required,
        touched: false,
      },
      valid: user.accetta_regolamento ? true : !obj['accetta_regolamento']?.required,
    },
    accetta_regolamento_concorso: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accetta_regolamento_concorso',
        id: 'accetta_regolamento_concorso',
      },
      label: obj['accetta_regolamento_concorso']?.label,
      value: user?.accetta_regolamento_concorso || 0,
      hint: obj['accetta_regolamento_concorso']?.hint,
      validation: {
        required: obj['accetta_regolamento_concorso']?.required,
        touched: false,
      },
      valid: user?.accetta_regolamento_concorso
        ? true
        : !obj['accetta_regolamento_concorso']?.required,
    },
    newsletter_subscription: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'newsletter_subscription',
        id: 'newsletter_subscription',
      },
      label: obj['newsletter_subscription']?.label,
      value: user?.newsletter_subscription || 0,
      hint: obj['newsletter_subscription']?.hint,
      validation: {
        required: obj['newsletter_subscription']?.required,
        touched: false,
      },
      valid: user.newsletter_subscription ? true : !obj['newsletter_subscription']?.required,
    },
  };
};
export const checkoutForm = (user, isMinimal) => {
  return {
    firstname: {
      elementType: 'input',
      // title: 'Nome',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome',
      },
      label: 'Nome',
      value: user.firstname,
      validation: {
        required: true,
        touched: false,
      },
      errorMessage: `is required`,
      valid: user.firstname ? true : false,
    },
    lastname: {
      elementType: 'input',
      // title: 'Cognome',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: `Cognome`,
      },
      label: 'Cognome',
      value: user.lastname,
      validation: {
        required: true,
        touched: false,
      },
      errorMessage: `is required`,
      valid: user.lastname ? true : false,
    },
    address1: {
      elementType: 'inputAddress',
      elementConfig: {
        type: 'text',
        name: 'address1',
        id: 'address1',
        placeholder: 'Indirizzo',
      },
      label: 'Indirizzo',
      value: user.address1,
      validation: {
        required: !isMinimal,
        touched: false,
      },
      errorMessage: `is required`,
      valid: user.address1 || isMinimal ? true : false,
    },
    civico: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'civico',
        id: 'civico',
        placeholder: 'N. civico',
      },
      label: 'N. civico',
      value: user.civico || '',
      validation: {
        required: !isMinimal,
        touched: false,
      },
      errorMessage: `is required`,
      valid: user.street_number || isMinimal ? true : false,
    },
    country: {
      elementType: 'select',
      isCountry: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona paese',
            disabled: true,
          },
        ],
        placeholder: 'Paese',
        name: 'country',
      },
      label: 'Paese',
      value: user.country ? user.country : '',
      validation: {
        required: !isMinimal,
        touched: false,
      },
      errorMessage: `is required`,
      valid: user.country || isMinimal ? true : false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona provincia',
            disabled: true,
          },
        ],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: 'Provincia',
      value: user.district ? user.district : '',
      validation: {
        required: !isMinimal,
        touched: false,
      },
      errorMessage: `is required`,
      valid: user.district || isMinimal ? true : false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona città',
            disabled: true,
          },
        ],
        placeholder: 'Città',
        name: 'city',
      },
      label: 'Città',
      value: user.city ? user.city : '',
      validation: {
        required: !isMinimal,
        touched: false,
      },
      errorMessage: `is required`,
      valid: user.city || isMinimal ? true : false,
    },
    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'zipcode',
        id: 'zipcode',
        placeholder: 'CAP',
      },
      label: `CAP`,
      value: user.zipcode,
      validation: {
        required: !isMinimal,
        touched: false,
      },
      errorMessage: `is required`,
      valid: user.zipcode || isMinimal ? true : false,
    },
    phone: {
      elementType: 'input',
      // title: 'Phone',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: 'Cellulare',
      },
      label: 'Phone',
      value: user.phone,
      validation: {
        required: !isMinimal,
        touched: false,
      },
      errorMessage: `is required`,
      valid: user.phone || isMinimal ? true : false,
    },
    email: {
      elementType: 'input',

      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
      },
      label: 'Email',
      value: user.email,
      validation: {
        required: !isMinimal,
        touched: false,
      },
      errorMessage: `is required`,
      valid: user.email || isMinimal ? true : false,
    },
    shipping_notes: {
      elementType: 'textarea',
      elementConfig: {
        type: '',
        name: 'shipping_notes',
        id: 'shipping_notes',
        placeholder: 'Note',
      },
      label: 'Note',
      value: user.shipping_notes,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    update_user: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'update_user',
        id: 'update_user',
      },
      label: '<p>Vuoi salvare le informazioni?</p>',
      value: 0,

      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
  };
};

export const filtersform = (filters, pointsLabel) => {
  return {
    macroCategory: {
      elementType: 'select',
      isMacrocategoria: true,
      title: 'Macrocategoria',
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: `Tutte`,
          },
        ],
        name: 'macroCategory',
      },
      label: '',
      value: filters['macro_categories'] ? filters['macro_categories'] : '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
      isReset: true,
    },
    category: {
      elementType: 'select',
      isCategories: true,
      title: 'Categoria',
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: `Tutte`,
          },
        ],
        name: 'category',
      },
      label: '',
      value: filters['categories'] ? filters['categories'] : '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
      isReset: true,
    },

    brand: {
      elementType: 'select',
      isBrands: true,
      title: 'Brand',
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: `Tutte`,
          },
        ],
        name: 'brand',
      },
      label: '',
      value: filters['brand'] ? filters['brand'] : '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
      isReset: true,
    },
    puntiDa: {
      elementType: 'input',
      title: pointsLabel,
      elementConfig: {
        type: 'number',
        name: 'puntiDa',
        id: 'puntiDa',
        placeholder: 'Da',
      },
      label: '',
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
      isReset: true,
    },
    puntiA: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'puntiA',
        id: 'puntiA',
        placeholder: 'A',
      },
      label: '',
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
      isReset: true,
    },
    search: {
      elementType: 'input',
      title: 'Ricerca libera',
      icon: 'search',
      elementConfig: {
        type: 'text',
        name: 'search',
        id: 'search',
        placeholder: 'Tablet',
      },
      label: '',
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
      isReset: true,
    },

    /* only_reacheble: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'only_reacheble',
        id: 'only_reacheble',
      },
      label: 'Visualizza solo premi richiedibili',
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    }, */
  };
};

export const reviewForm = ref => {
  return {
    title: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'title',
        id: 'title',
        placeholder: 'Title',
        ref,
      },
      label: ``,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    description: {
      elementType: 'textarea',
      elementConfig: {
        type: '',
        name: 'description',
        id: 'description',
        placeholder: 'Description',
      },
      label: ``,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};
