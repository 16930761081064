import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import styled from 'styled-components/macro';

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  flex-grow: 1;
  svg {
    background-color: ${({ icon, theme }) => icon === 'search' && theme.bg_grey_light};
    height: 50px;
    width: 47px;
    padding: 10px;
  }
  .show_pw {
    width: 100%;
    margin: 15px 0 0 45px;
  }
`;

const InputField = styled.input`
  // border: none;
  color: ${props => props.theme.colore_testo_campi};
  padding: 0 15px;
  background-color: ${props => props.theme.input.bg};
  border-radius: 4px;
  width: ${props => (props.type === 'checkbox' ? '20px' : 'auto')};
  flex-grow: 1;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  opacity: ${props => (props.readonly ? '0.3' : '1')};
  border: 2px solid
    ${props => {
      return (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.bg;
    }};
  &::placeholder {
    color: #47545d59;
  }
`;
const fields = {
  country: 'country',
  // region: 'administrative_area_level_1',
  address1: 'route',
  civico: 'street_number',
  district: 'administrative_area_level_2',
  city: 'locality',
  zipcode: 'postal_code',
};
const InputAddress = props => {
  const [address, setAddress] = React.useState(props.value);
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null,
  });

  const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const addressComponents = results[0].address_components;
    for (const key in fields) {
      if (
        key !== 'zipcode' &&
        key !== 'civico' &&
        addressComponents.find(component => component.types.includes('country'))?.['long_name'] ===
          'San Marino'
      ) {
        props.form[key].value = 'San Marino';
        props.form[key].valid = true;
      } else if (addressComponents.find(component => component.types.includes(fields[key]))) {
        props.form[key].value = addressComponents.find(component =>
          component.types.includes(fields[key]),
        )[key === 'locality' ? 'long_name' : 'short_name'];
        props.form[key].valid = true;
      } else {
        props.form[key].value = '';
        props.form[key].valid = false;
      }
    }

    setAddress(
      props.form['address1'].value === 'San Marino'
        ? 'San Marino'
        : addressComponents.find(component => component.types.includes('route'))?.long_name,
    );

    props.onChange({
      target: {
        name: props.name,
        value:
          props.form['address1'].value === 'San Marino'
            ? 'San Marino'
            : addressComponents.find(component => component.types.includes('route'))?.long_name,
      },
    });
  };
  const handleChange = address => {
    setAddress(address);
    props.onChange({ target: { name: props.name, value: address } });
    if (!address) {
      props.form.address1.value = '';
      props.form.address1.valid = false;
      props.form.civico.value = '';
      props.form.civico.valid = false;
      props.form.district.value = '';
      props.form.district.valid = false;
      props.form.city.value = '';
      props.form.city.valid = false;
      props.form.zipcode.value = '';
      props.form.zipcode.valid = false;
      props.form.country.value = '';
      props.form.country.valid = false;
    }
  };
  return (
    <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <div style={{ width: '100%', position: 'relative', zIndex: '10' }}>
            <InputField {...props} {...getInputProps()} style={{ width: '100%' }} />
            <div
              style={{ position: 'absolute', width: '100%', top: '100%', left: '0', zIndex: '10' }}
            >
              {loading ? <div>...loading</div> : null}

              {suggestions.map(suggestion => {
                const style = {
                  backgroundColor: suggestion.active ? '#ddd' : '#eee',
                  padding: '10px',
                  cursor: 'pointer',
                };
                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default InputAddress;
